import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Is Node.js A Programming Language?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Node.js is not a traditional programming language. It is a runtime environment for executing javascript on backend servers."        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Can You Use Node.js For?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Node.js is used to build real-time applications that generate a big number of I/O operations which means applications expecting high traffic load. Such applications include social networking app, gaming applications, instant messaging apps, etc.With Codestaff, you are sure to get the best service."
        }
    }
}

const Nodejs = () => (
    <main>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <section className="cx_catagories">
            <div className="container-fluid cx_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-10">
                            <h2>
                                Hire top freelance<br /><span> Node.js Developers.</span>
                            </h2>
                            <p>
                                Codestaff is a marketplace for top Node.js Developers. Top
                                companies and start-ups choose Codestaff professional Node.js
                                Developers for their mission critical software projects.
                            </p>
                            <Link to="/hire/" className="skills-banner-btn">
                                Hire a top Node.js Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <CxSearch title='HIRE FREELANCE NODE.JS DEVELOPERS' banner='/nodejs.png' bannerAlt='nodejs banner' />
        </section>
        <HireAndApply />
        <TopTrendingSkills />
    </main>
)

export default Nodejs